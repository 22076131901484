<template>
  <div class="page-home">
    <van-form ref="form" @submit="onSubmit">
      <div class="index_title">{{ result.title }}</div>
      <div class="hall_img">
        <img src="../../assets/yw.png">
      </div>
      <div class="hall_place">
        {{ businessdata.business }}
      </div>
      <div class="hall_desc">
        {{ businessdata.prompted }}
      </div>
      <!--    v-if="businessdata.is_input === '1'"   -->
      <div v-if="businessdata.is_input === '1'" class="hall_box">
        <van-cell-group :title="businessdata.input_title">
          <van-field v-model="verify" :placeholder="'请输入' + businessdata.input_title" :rules="[{ required: true }]">
            <template #button>
              <img src="../../assets/scan.png" width="25" @click="scanCode">
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div v-if="businessdata.is_idcard === '1'" class="user-box">
        <van-cell-group title="姓名">
          <van-field v-model="name" placeholder="请输入姓名" :rules="[{ required: true }]" />
        </van-cell-group>
        <van-cell-group title="身份证">
          <van-field v-model="id_card" maxlength="18" placeholder="请输入身份证" :rules="[{ required: true }, {validator: validatorIDCard, message:'身份证错误'}]" />
        </van-cell-group>
      </div>
      <div class="hall_btn">
        <!--        <van-button v-if="businessdata.is_input === '1'" type="primary" block color="#106FFF" class="btn" :disabled="verify.length >= 1 ? false : true" native-type="submit">确认取号</van-button>-->
        <van-button type="primary" block color="#106FFF" class="btn" :loading="btnLoading" loading-text="取号中..." native-type="submit">确认取号</van-button>
        <van-button plain type="primary" block color="#106FFF" @click.native="onCancel">取消</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getBusinessById, getNumber } from '@/api/data' // getNumber
import { getBranch } from '@/utils/auth'
import { mapGetters } from 'vuex'
import wx from 'weixin-js-sdk'
import { getJssdkConfig } from '@/api/app'
import { Button, Empty, Col, Row, Dialog, Toast, Field, CellGroup, Form } from 'vant'
// import Cookies from 'js-cookie'

export default {
  inject: ['routerRefresh'], // 在子组件中注入在父组件中创建的属性
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      statusdata: '',
      status: 1,
      timer: '',
      listTimer: '',
      disable: true,
      value: 0,
      result: '',
      sysset_id: '',
      branch: getBranch(),
      business_id: '',
      businessdata: '',
      verify: '',
      name: '',
      id_card: '',
      btnLoading: false
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo',
      'branchInfo'
    ])
  },
  // watch: {
  //   '$route'(to, from) {
  //     this.$router.go(0)
  //   }
  // },
  mounted() {
    // clearInterval(this.timer)
    // clearInterval(this.listTimer)
  },
  created() {
    console.log('this.userInfo')
    console.log(this.userInfo)
    console.log('this.userInfo')
    if (this.userInfo) {
      this.name = this.userInfo.name
      this.id_card = this.userInfo.id_card
    }
    this.getConfig()
    if (this.$route.query.sysset_id) {
      this.disable = false
      this.sysset_id = this.$route.query.sysset_id
      this.getnetworkInfo(this.sysset_id)
    } else {
      if (this.branch) {
        this.disable = false
        this.result = JSON.parse(this.branch)
        this.sysset_id = this.result.id
        this.getnetworkInfo(this.sysset_id)
      } else {
        Toast({
          type: 'fail',
          duration: '3000',
          message: '地址不正确,请重新扫码',
          forbidClick: true
        })
      }
    }
    if (this.$route.query.business_id) {
      this.business_id = this.$route.query.business_id
      this.getBusinessInfo()
    } else {
      Toast({
        type: 'fail',
        duration: '1500',
        message: '请选择要办理的业务',
        forbidClick: true,
        onClose: () => {
          this.$router.push({ name: 'home.index', query: { sysset_id: this.result.id }})
          this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
        }
      })
    }
  },
  methods: {
    validatorIDCard(val) {
      return /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))$/.test(val)
    },
    getConfig() {
      // console.log("授权地址", window.entryUrl)
      // const ua = navigator.userAgent.toLowerCase()
      // if (/iphone|ipad|ipod/.test(ua)) {
      //   this.newUrl = window.location.href.split('#')[0]
      // } else if (/android/.test(ua)) {
      //   this.newUrl = window.location.href
      // }
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
      if (isAndroid) {
        this.newUrl = window.location.href
      }
      if (isiOS) {
        this.newUrl = window.entryUrl
      }

      getJssdkConfig({ 'url': this.newUrl }).then((response) => {
        wx.config({
          debug: false,
          appId: response.data.appId,
          timestamp: '' + response.data.timestamp,
          nonceStr: response.data.nonceStr,
          signature: response.data.signature,
          jsApiList: ['checkJsApi', 'scanQRCode']
        })
        wx.error(function(res) {
          console.log('出错了：' + res.errMsg)
        })
      }).catch(err => {
        console.log(err)
        this.$router.push({ name: 'home.index' })
        this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
      })
    },
    scanCode() {
      const that = this
      wx.ready(function() {
        wx.checkJsApi({
          jsApiList: ['scanQRCode'],
          success: function(res) {
            wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
              success: function(res) {
                var result = res.resultStr.split(',') // 当needResult 为 1 时，扫码返回的结果
                that.verify = result[1]
              }
            })
          }
        })
      })
    },
    getnetworkInfo(values) {
      this.loading = true
      this.$store.dispatch('app/getRule', values).then(res => {
        this.result = res
        this.verify = ''
        this.loading = false
      })
    },
    getBusinessInfo() {
      if (this.$route.query.business_id) {
        this.business_id = this.$route.query.business_id
        getBusinessById({ 'business_id': this.business_id }).then((response) => {
          this.businessdata = response.data
        }).catch(err => {
          console.log(err.message)
        })
      }
    },
    onSubmit() {
      this.btnLoading = true
      const parame = {
        sysset_id: this.result.id,
        business_id: this.business_id
      }
      if (this.verify) {
        parame.input = this.verify
      }
      if (this.name) {
        parame.name = this.name
      }
      if (this.id_card) {
        parame.id_card = this.id_card
      }
      this.$refs.form.validate().then(() => {
        if (this.result.status === '1') {
          getNumber(parame).then((response) => {
            this.numberdata = response.data
            Toast({
              type: 'success',
              message: '取号成功',
              forbidClick: true,
              onClose: () => {
                this.$router.push({ name: 'number.index', query: { sysset_id: this.result.id }})
                this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
                this.btnLoading = false
              }
            })
          }).catch(err => {
            this.btnLoading = false
            if (err.message === '请勿重复取号') {
              this.verify = ''
              Toast({
                type: 'fail',
                message: err.message,
                forbidClick: true,
                onClose: () => {
                  this.$router.push({ name: 'number.index', query: { sysset_id: this.result.id }})
                  this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
                }
              })
            } else {
              this.verify = ''
              Toast({
                type: 'fail',
                message: err.message,
                forbidClick: true,
                onClose: () => {
                  this.$router.push({ name: 'user.login', query: { 'redirect': 'home' }})
                  this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
                }
              })
            }
          })
        } else {
          this.$router.push({ name: 'close.found' })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    onCancel() {
      this.$router.push({ name: 'home.index', query: { sysset_id: this.result.id }})
      this.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    }
  }
}
</script>

<style lang="less" scoped>
  .index_title{width: 100%;float: left;height: 50px;line-height: 50px; text-align: center;font-size: 16px; color: #26272C;border-bottom: 1px #F7F7F7 solid;font-family: 'Microsoft YaHei';}
  .page-home{width: 100%;float: left; background: #ffffff;overflow-y: scroll;}
  .hall_img{width: 100%;float: left; min-height: calc(30vh);margin: 0 0 30px 0; display: flex;align-items: flex-end;justify-content: center;}
  .hall_img img{width: 50%;}
  .hall_place{ width: 90%;margin: 0px 5%; line-height: calc(3vh); float: left; height: calc(6vh);text-align: center;color: #101112;font-weight: bold;font-size: 18px;}
  .hall_name{ width: 100%;float: left; height: calc(6vh);line-height: calc(6vh);text-align: center;color: #106FFF;font-size: 16px;}
  .hall_btn{width: 90%;   margin: calc(2vh) 5% calc(4vh) 5%;min-height: calc(15vh); float: left;}
  .hall_btn .btn{margin-bottom: calc(2vh)}
  .hall_desc{width: 90%;margin: 0px 5%;float: left;min-height: calc(10vh); line-height: 1.5em;font-size: 16px;color: #8993A8;text-align: center;}
  .hall_box{width: 90%;margin: 0px 5%; float: left; }
  .hall_box /deep/ .van-cell-group__title{color: #26272C;font-size: 15px;}
  .hall_box /deep/ .van-cell{background: #F7F9FC;border-radius: 10px; padding: 2px 0 2px 10px;}
  /deep/ .van-field__control{height: 40px;}
  /deep/ .van-field__right-icon{display: flex;justify-content: center;align-items: center;}
  .van-field__right-icon img{margin-right: 10px;}
  .user-box{width: 90%;margin: 0px 5% 15px 5%;min-height: calc(15vh); float: left;}
  .user-box /deep/ .van-cell{background: #F7F9FC;border-radius: 10px; padding: 2px 0 2px 10px;margin: 0 0 10px 0;}
</style>
